<template>
    <div v-if="board.id" class="board-view">
        <create-board-modal @edited-board="(editedBoard) => board = editedBoard" />
        <delete-board-modal />
        <side-bar v-if="!isLoggedIn" />
        <div class="container main-content">
            <div class="row no-gutters board-view-header">
                <div class="col-auto board-back">
                    <back-button />
                </div>
                <div class="col board-title-and-user">
                    <h1>{{ board.list_name }}</h1>
                    <user-picture-name-nickname :user="board.user" />
                </div>
                <div class="col-auto board-options">
                    <v-popover
                        v-if="isBoardAuthor"
                    >
                        <div class="tooltip-target b3">
                            <img src="@assets/img/icons/three-dots.svg" alt="Options">
                        </div>

                        <template slot="popover">
                            <div class="popover-options infinite">
                                <div class="popover-option" @click="editBoard">
                                    <span>Edit</span>
                                    <img
                                        src="@assets/img/icons/edit.svg"
                                        class="edit"
                                        alt="edit"
                                    >
                                </div>
                                <div class="popover-option" @click="deleteBoard">
                                    <span>Delete</span>
                                    <img
                                        src="@assets/img/icons/trash-can.svg"
                                        class="delete"
                                        alt="delete"
                                    >
                                </div>
                            </div>
                        </template>
                    </v-popover>
                    <follow-entity-button
                        v-else
                        :entity-id="board.id"
                        :entity-name="board.list_name"
                        :is-following="Number(board.is_followed)"
                        entity="boards"
                        @toggled-follow="(is_following) => board.is_followed = is_following"
                    />
                </div>
            </div>
            <memod-list :list-endpoint="`/boards/${board.id}/memos?`" :no-memos-found-message="noMemosFoundMessage" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "BoardView",
    metaInfo() {
        return {
            titleTemplate: this.board.list_name,
            meta: [
                {
                    hid: "og:title",
                    property: "og:title",
                    content: `${this.board.list_name}`,
                    vmid: "og:title"
                },
                {
                    hid: "twitter:title",
                    name: "twitter:title",
                    content: `${this.board.list_name}`,
                    vmid: "twitter:title"
                },
                {
                    hid: "description",
                    property: "description",
                    content: this.boardMetaDescription,
                    vmid: "description"
                },
                {
                    hid: "og:description",
                    property: "og:description",
                    content: this.boardMetaDescription,
                    vmid: "og:description"
                },
                {
                    hid: "twitter:description",
                    name: "twitter:description",
                    content: this.boardMetaDescription,
                    vmid: "twitter:description"
                },
                {
                    hid: "og:image",
                    property: "og:image",
                    content: this.board.image_url,
                    vmid: "og:image"
                },
                {
                    hid: "twitter:image",
                    name: "twitter:image",
                    content: this.board.image_url,
                    vmid: "twitter:image"
                }
            ]
        }
    },
    components: {
        UserPictureNameNickname: () => import(/* webpackChunkName: "user-picture-name-nickname" */ "@c/molecules/user-picture-name-nickname"),
        BackButton: () => import(/* webpackChunkname: "back-button" */ "@c/atoms/back-button"),
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue"),
        CreateBoardModal: () => import(/* webpackChunkName: "create-board-modal" */ "@/components/organisms/modals/create-board.vue"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-user-button" */ "@c/atoms/follow-entity-button"),
        DeleteBoardModal: () => import(/* webpackChunkName: "delete-board-modal" */ "@/components/organisms/modals/delete-board.vue"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar")
    },
    data() {
        return {
            board: {},
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "No Memos saved to this Board...yet!"
            }
        };
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        }),
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        isBoardAuthor() {
            return Boolean(Number(this.board.user.id) === Number(this.userId));
        },
        boardMetaDescription() {
            return `Explore @${this.board.user?.displayname} collection of Memos on Writing Ideas`;
        }
    },
    watch: {
        "$route.params": {
            immediate: true,
            handler(newRoute, oldRoute) {
                if (Number(newRoute?.id) != Number(oldRoute?.id)) {
                    this.fetchBoard(this.$route.params.id);
                }
            }
        }
    },
    methods: {
        fetchBoard(boardId) {
            this.$emit("is-loading", true);
            window.scrollTo(0, 0);
            axios.get(`/boards/${boardId}`).then(({ data }) => {
                this.board = data;
                this.$store.dispatch("Boards/setCurrentBoard", data);
            }).catch((error) => {
                console.log(error.response.data.message);
                this.$router.push({
                    name: "404"
                });
            }).finally(() => {
                this.$emit("is-loading", false);
            });
        },
        editBoard() {
            this.$modal.show("create-board-modal", { isEdit: true, boardToEdit: { title: this.board.list_name, is_public: this.board.is_public, id: this.board.id } })
        },
        deleteBoard() {
            this.$modal.show("delete-board", { boardId : this.board.id, boardTitle: this.board.list_name, redirectRoute: { name: "web-app-profile-boards" } });
        }
    }
}
</script>

<style lang="scss" scoped>
.board-view {
    .main-content {
        padding-top: 100px;
        margin-bottom: 120px;

        .back-button {
            margin-right: 40px;
        }

        h1 {
            padding-top: 7px;
            margin-bottom: 15px;
        }

        .tooltip-target {
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media(max-width: $md) {
            padding-top: 20px;

            .board-view-header {
                position: relative;

                .board-back {
                    order: 1;
                    flex-basis: 100%;
                }

                .board-title-and-user {
                    order: 3;

                    h1 {
                        text-align: center;
                    }

                    .user-picture-name-followers {
                        display: flex;
                        justify-content: center;
                    }
                }

                .board-options {
                    order: 2;
                    flex: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}
</style>
